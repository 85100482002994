import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Logo from '../Assets/Logo.png';
// import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import FoodBankIcon from '@mui/icons-material/FoodBank';
import YardIcon from '@mui/icons-material/Yard';
import PetsIcon from '@mui/icons-material/Pets';
import PestControlIcon from '@mui/icons-material/PestControl';
// import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const scrollToSection = (sectionId) => {
      scroll.scrollTo(document.getElementById(sectionId).offsetTop - 50);
      setOpenMenu(false);
    };
    const menuOptions = [
      { text: 'Home', icon: <HomeIcon />, section: 'home' },
      { text: 'Sobre nós', icon: <InfoIcon />, section: 'about' },
      // { text: 'Testemunhos', icon: <CommentRoundedIcon />, section: 'testimonial' },
      { text: 'Contato', icon: <PhoneRoundedIcon />, section: 'contact' },
      // { text: 'Carrinho', icon: <ShoppingCartRoundedIcon />, section: 'cart' },
      { text: 'Endereço', icon: <MapsHomeWorkIcon />, section: 'address' },
      { text: 'Alimentos', icon: <FoodBankIcon />, section: 'food' },
      { text: 'Fertilizantes', icon: <YardIcon />, section: 'fertilizer' },
      { text: 'Inseticidas', icon: <PestControlIcon />, section: 'insecticide' },
      { text: 'Pets', icon: <PetsIcon />, section: 'pet' },
    ];
    return (
      <nav>
        <div className="nav-logo-container">
          <img src={Logo} alt="RKS Agro" />
        </div>
        <div className="navbar-links-container">
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
          <Link to="about" smooth={true} duration={500}>
            Sobre nós
          </Link>
          {/* <Link to="testimonial" smooth={true} duration={500}>
            Testemunhos
          </Link> */}
          <Link to="contact" smooth={true} duration={500}>
            Contato
          </Link>
          <Link to="address" smooth={true} duration={500}>
            Endereço
          </Link>
          {/* <Link to="cart" smooth={true} duration={500}>
            <BsCart2 className="navbar-cart-icon" />
          </Link>           */}
          <Link to="food" smooth={true} duration={500}>
            Alimentos
          </Link>
          <Link to="fertilizer" smooth={true} duration={500}>
            Fertilizantes
          </Link> 
          <Link to="insecticide" smooth={true} duration={500}>
            Inseticidas
          </Link>
          <Link to="pet" smooth={true} duration={500}>
            Pets
          </Link>
        </div>

        <div className="navbar-menu-container">
          <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => scrollToSection(item.section)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
        </Drawer>
      </nav>
    );
};

export default Navbar;