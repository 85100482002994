import React from "react";

import BannerImage from "../Assets/oleo-de-neem3.png";
import BannerImage2 from "../Assets/biomax-x2.png";
import BannerImage3 from "../Assets/embiotic2.png";
import BannerImage4 from "../Assets/korin3.png";
import BannerImage5 from "../Assets/granulado-confort-pet.png";
import BannerImage6 from "../Assets/aviva-flor2.png";
import BannerImage7 from "../Assets/arigatou-neem2.png";
import BannerImage8 from "../Assets/bordamil2.png";
import BannerImage9 from "../Assets/vicanutri2.png";
import BannerImage10 from "../Assets/sufolc.png";
import BannerImage11 from "../Assets/azufre2.png";
import BannerImage12 from "../Assets/banana-verde-150g2.png";
import BannerImage13 from "../Assets/banana-passa-150g.png";
import BannerImage14 from "../Assets/banana-passa-chocolate-misto-150g.png";
import BannerImage15 from "../Assets/banana-passa-chocolate-meio-amargo-150g.png";
import BannerImage16 from "../Assets/banana-passa-chocolate-branco-150g.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  const estiloDoLink = {
    textDecoration: 'none', // Remover sublinhado.
  };
  const insecticideItems = [
    { title: 'Óleo de neen', text: 'A solução que sua plantação precisa. Proteja sua plantação com o Óleo de Neem. Inovação sustentável para sua agricultura, respeitando a natureza e garantindo resultados. Melhor relação custo x benefício. Respeita os inimigos naturais e polinizadores. Diversas formas de ação sobre os insetos, sem gerar resistência. Totalmente biodegradável e não bioacumulável. Pode ser combinado com controle biológico. Embalagem de 100 ml, de 1 litro e de 5 litros.', photo: BannerImage },
    { title: 'Biomax-x', text: 'Não se preocupe com moscas e insetos. Uso em plantas e ambientes. 100% natural. Sem odor. Pronto para uso. Não agride animais e humanos. Ele age contra mais de 550 insetos e transmissores de doenças e pragas, como mosquitos, mosca, formiga, barata, cupim, pulga, piolho, carrapato, lagarta, ácaro e fungo. Também é eficaz na prevenção e combate do mosquito e larva da dengue. Biomax-x pode ser aplicado nas frutas e hortaliças da sua horta para controlar a infestação dos inimigos naturais das plantas. Serve também para conter parasitas em animais de sangue quente. Você pode passar no local de repouso ou pulverizar o dorso e lombo de cães, gatos, equinos e bovinos para afastar pulgas, carrapatos e sarnas. Dentro de casa, se tiver formigas, baratas, ácaros e fungos, Biomax atua de maneira efetiva e sem deixar cheiro nos ambientes. Embalagens de 1 litro e 5 litros.', photo: BannerImage2 },
    { title: 'Embiotic Bioremediador', text: 'Uma solução inovadora para o tratamento de resíduos orgânicos. Embalagem de 5 litros.', photo: BannerImage3 },
  ];
  const petItems = [
    { title: 'Korin Limpador e Neutralizador de Odores', text: 'Por meio de componentes naturais promove um ambiente agradável e harmonioso de forma prolongada, neutralizando o mau odor gerado pelos resíduos orgânicos do seu pet, reduzindo frequência de limpeza do local e proporcionando às pessoas e aos pets maior qualidade de vida e o respeito que els e o nosso planeta necessitam e merecem. Embalagem de 500 ml', photo: BannerImage4 },
    { title: 'Granulado Confort Pet', text: 'Granulado higiênico de madeira reflorestada de pinus é um produto desenvolvido para a higiêne do seu gato, mas pode ser também utilizado para hamsters, coelhos e outros pequenos animais. 100% natural e ecológicamente correto. Embalagem de 2 Kg.', photo: BannerImage5 },
  ];
  const fertilizerItems = [
    { title: 'Aviva Flor', text: 'Fertilizante orgânico composto para vasos, hortas e jardins. Modo de aplicação é via foliar. Embalagem de 500 ml.', photo: BannerImage6 },
    { title: 'Arigatou-neem', text: 'Fertilizante orgânico e controle de doenças em plantas no solo; e para animais controla parasitas. Natural, eficaz e prática no combate a carraptos, bernes e moscas. Pode ser adicionada à dieta dos animais em mistura com sal mineral ou ração em uma proporção de 1 a 2% (p/p) para controle de parasitas. Viável economicamente. Fácil manejo. Ecologicamente correta. Após misturado com o sal ou ração, deve ser consumido em no máximo 60 dias. Embalagem de 1 Kg.', photo: BannerImage7 },
    { title: 'Bordamil', text: 'Fertilizante foliar, que nutre e protege a planta contra a ação de fungos e bactérias. Composição: Cobre (Cu) 20,0%; Cálcio (Ca) 5,0%; Enxofre (SO4) 10,0%. Embalagens de 20 gramas (sachê), e pacotes de 1 Kg.', photo: BannerImage8 },
    { title: 'Viçanutri', text: 'Fertilizante completo com micronutrientes essenciais. Auxilia na indução de resistência da planta contra doenças fúngicas e bacterianas. Embalagens de 20 gramas (sachê), e pacotes de 1 Kg.', photo: BannerImage9 },
    { title: 'Sufolc', text: 'Fertilizante foliar que graças a ação do Enxofre e Cálcio atua auxiliando no controle de cochonilhas, ácaros e mofo branco. Embalagens de 20 gramas (sachê), e pacotes de 1 Kg.', photo: BannerImage10 },
    { title: 'Azufre', text: 'Fertilizante Foliar composto de ( 80% de Enxofre e 1% de Manganês) muito eficaz para auxiliar no combate de pragas e doenças pelas características do Enxofre. Embalagens de 20 gramas (sachê), e pacotes de 1 Kg.', photo: BannerImage11 },
  ];
  const foodItems = [
    { title: 'Farinha de banana verde', text: 'A farinha de banana verde pode ser misturada a sucos, vitaminas e sopas, consumida com frutas ou integrar massas de tortas, bolos e pães. Proveniente de agricultura orgânica e biodinâmica.  Embalagens de 150 e 500 gramas, e de 10 Kg.', photo: BannerImage12 },
    { title: 'Banana passa', text: 'Banana passa. Proveniente de agricultura orgânica e biodinâmica. Embalagem de 150 gramas.', photo: BannerImage13 },
    { title: 'Banana passa com chocolate misto', text: 'Banana passa com chocolate misto. Proveniente de agricultura orgânica e biodinâmica. Embalagem de 150 gramas.', photo: BannerImage14 },
    { title: 'Banana passa com chocolate meio amargo', text: 'Banana passa com chocolate meio amargo. Proveniente de agricultura orgânica e biodinâmica. Embalagem de 150 gramas.', photo: BannerImage15 },
    { title: 'Banana passa com chocolate branco', text: 'Banana passa com chocolate branco. Proveniente de agricultura orgânica e biodinâmica. Embalagem de 150 gramas.', photo: BannerImage16 },
  ];

  const handleWhatsAppButton = (title) => {
    window.dataLayer.push({
      'event': 'whatsappButton',
      'eventCategory': 'Button Click',
      'eventAction': 'Click',
      'eventLabel': title,
    });
  }
  return (
    <div id="home" className="home-container">

      <Navbar />

      <div className="product-section">
        <h2>Alimentícios</h2>      
      </div>

      <div id="food">
        {foodItems.map((item) => 
          <div key={item.title} className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading">
                {item.title}
              </h1>
              <p className="primary-text">
                {item.text}
              </p>
              <a href={"https://wa.me/5547999107008?text=Olá, quero comprar o produto "+item.title+"!"} style={estiloDoLink}target="_blank" rel="noreferrer">
                <button className="secondary-button" onClick={() => handleWhatsAppButton(item.title)}>               
                  Compre agora <FiArrowRight />{" "}
                </button>
              </a>     
            </div>
            <div className="home-image-section">
              <img src={item.photo} alt={item.title} />
            </div>
          </div>
        )}
      </div>

      <div className="divider"></div>
      
      <div className="product-section">
        <h2>Fertilizantes</h2>      
      </div>

      <div id="fertilizer">
        {fertilizerItems.map((item) => 
          <div key={item.title} className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading">
                {item.title}
              </h1>
              <p className="primary-text">
                {item.text}
              </p>
              <a href={"https://wa.me/5547999107008?text=Olá, quero comprar o produto "+item.title+"!"} style={estiloDoLink}target="_blank" rel="noreferrer">
                <button className="secondary-button" onClick={() => handleWhatsAppButton(item.title)}>
                  Compre agora <FiArrowRight />{" "}
                </button>
              </a>     
            </div>
            <div className="home-image-section">
              <img src={item.photo} alt={item.title} />
            </div>
          </div>
        )}
      </div>

      <div className="divider"></div>

      <div className="product-section">
        <h2>Inseticidas</h2>      
      </div>

      <div  id="insecticide">
        {insecticideItems.map((item) => 
          <div key={item.title} className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading">
                {item.title}
              </h1>
              <p className="primary-text">
                {item.text}
              </p>
              <a href={"https://wa.me/5547999107008?text=Olá, quero comprar o produto "+item.title+"!"} style={estiloDoLink}target="_blank" rel="noreferrer">
                <button className="secondary-button" onClick={() => handleWhatsAppButton(item.title)}>
                  Compre agora <FiArrowRight />{" "}
                </button>
              </a>     
            </div>
            <div className="home-image-section">
              <img src={item.photo} alt={item.title} />
            </div>
          </div>
        )}
      </div>

      <div className="divider"></div>

      <div className="product-section">
        <h2>Pet</h2>      
      </div>

      <div id="pet">
        {petItems.map((item) => 
          <div key={item.title} className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading">
                {item.title}
              </h1>
              <p className="primary-text">
                {item.text}
              </p>
              <a href={"https://wa.me/5547999107008?text=Olá, quero comprar o produto "+item.title+"!"} style={estiloDoLink}target="_blank" rel="noreferrer">
                <button className="secondary-button" onClick={() => handleWhatsAppButton(item.title)}>
                  Compre agora <FiArrowRight />{" "}
                </button>
              </a>     
            </div>
            <div className="home-image-section">
              <img src={item.photo} alt={item.title} />
            </div>
          </div>
        )}
      </div>
      
    </div>
  );
};


export default Home;