import React from "react";
import Logo from "../Assets/Logo.png";
import { FaInstagramSquare } from "react-icons/fa";
import { Link } from 'react-scroll';
import { FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => { 
  const estiloDoLink = {
    textDecoration: 'none', // Remover sublinhado
  };
  return (
    <div>
      <div id="footer" className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="footer-icons">
            <a href='https://www.instagram.com/rksagro/' target="_blank" rel="noreferrer">
              <FaInstagramSquare className="hover:text-[#c32aa3] transition-colors duration-200 delay-200" />
            </a>
          </div>
        </div>

        <div className="footer-section-two">
          <div className="footer-section-columns"> 
            <Link to="home" smooth={true} duration={500}>
              <span>Home</span>
            </Link>
            <Link to="food" smooth={true} duration={500}>
              <span>Alimentos</span>
            </Link>
            <Link to="fertilizer" smooth={true} duration={500}>
              <span>Fertilizantes</span>
            </Link>
            <Link to="insecticide" smooth={true} duration={500}>
              <span>Inseticidas</span>
            </Link>
            <Link to="pet" smooth={true} duration={500}>
              <span>Pets</span>
            </Link>
          </div>
          <div className="footer-section-columns">
            <a href="https://wa.me/5547999107008/" style={estiloDoLink} target="_blank" rel="noreferrer">
              <FaWhatsapp style={estiloDoLink} />
              /
              <FiPhoneCall style={estiloDoLink} />
              <span>047-99910-7008</span>
            </a>
          
            <span>contatorksagro@gmail.com</span>
          </div>

        {/* <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div> */}
      </div>
        
      </div>
      <div>
      <div id="address">
          <p className="address-text">
            RKS Atacado de Produtos Agropeguários Ltda. - <strong>CNPJ</strong> 26.504.461.0001-95 - <strong>CEP</strong> 89128-000. <strong>Sede administrativa:</strong> Rua Paulo Kraisch, nº 27 - Vila do Salto - Luiz Alves/SC.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;