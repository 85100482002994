import React from "react";
import { useState } from 'react';
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    

    if(name === '' || email === '' || message === '') {
      alert("Preeencha todos nos campos");
      return;
    }

    if(!regexEmail.test(email)) {
      alert("Preeencha o campo e-mail no formato adequado");
      return;
    }
    const templateParams = {
      from_name: name,
      message: message,
      email: email
    }
    emailjs.send("service_r76rhmg", "template_roqmihi", templateParams, process.env.REACT_APP_EMAILJS_PUBLIC_KEY.toString())
    .then((response) => {
      console.log("EMAIL ENVIADO", response.status, response.text);
      setName('');
      setEmail('');
      setMessage('');
    }, (err) => {
      console.log("ERRO: ", err);
    });
  }

  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Fale conosco</h1>
      <h1 className="primary-heading">Deixe-nos ajudá-lo</h1>
      {/* <div className="contact-form-container">
        <input type="text" placeholder="seumail@mail.com" />
        <button className="secondary-button">Submit</button>
      </div> */}

      <div className="contact-form-container">
        {/* <h1 className="title">Contato</h1> */}

        <form className="form" onSubmit={sendEmail}>
          <input 
            className="input"
            type="text"
            placeholder="Digite seu nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          
          <input 
            className="input"
            type="text"
            placeholder="Digite seu email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <textarea 
            className="textarea"
            placeholder="Digite sua mensagem..."
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />

          <input className="secondary-button" type="submit" value="Enviar" />
        </form>

      </div>
    </div>
  );
};

export default Contact;