import React from "react";

// import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div id="about" className="about-section-container">
      <div className="about-background-image-container"></div>
      <div className="about-section-image-container"></div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Sobre nós</p>
        <h1 className="primary-heading">
          Dedicamos compromisso à saúde e consciência ambiental
        </h1>
        <p className="primary-text">
          Oferecemos produtos de origem orgânica, biodinâmica e fertiprotetores, promovendo uma vida saudável e ecoconciente!
        </p>
        <p className="primary-text">
          Ao escolher a RKS Agro, você está fazendo mais do que simplesmente adquirir produtos; está se unindo a uma comunidade que valoriza a saúde, a sustentabilidade e a preservação do nosso planeta. Agradecemos por fazer parte dessa jornada conosco, e esperamos que nossos produtos contribuam para uma vida mais saudável e consciente para você e para o nosso mundo.
        </p>
        {/* <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default About;